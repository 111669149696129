import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { FormattedMessage, injectIntl } from 'react-intl';
import { Field, reduxForm, formValueSelector } from 'redux-form';

import messages from '../../../locale/messages';
import { change } from 'redux-form';
import withStyles from 'isomorphic-style-loader/withStyles';
import cx from 'classnames';
import s from './LoginForm.css';
import rs from '../../restaurantCommon.css';
import {
    Button,
    FormGroup,
    Col,
    Row,
    FormControl,
    Form
} from 'react-bootstrap';
import validate from './validate';
import submit from './submit';
import { openForgotPasswordModal } from '../../../actions/modalActions';
class LoginForm extends React.Component {
    static propTypes = {
        formatMessage: PropTypes.func
    }
    static defaultProps = {
        page: '1'
    }

    constructor(props) {
        super(props);
        this.state = {
            page: '1'
        }
        this.handleBack = this.handleBack.bind(this);
    }
    componentWillMount() {
        const { change } = this.props;
        change('page', '1');
        this.setState({
            page: '1'
        });
    }

    componentWillReceiveProps(nextProps) {
        const { page } = nextProps;
        if (page == 2) {
            this.setState({
                page: '2'
            });
        }

    }

    renderFormControl = ({ input, label, type, meta: { touched, error }, className }) => {
        const { formatMessage } = this.props.intl;
        return (
            <div>
                <FormControl {...input} placeholder={label} type={type} className={className} />
                {touched && error && <span className={rs.errorMessage}>{formatMessage(error)}</span>}
            </div>
        );
    }

    handleBack() {
        const { change } = this.props;
        change('page', '1');
        this.setState({
            page: '1'
        });
    }

    render() {
        const { error, handleSubmit, submitting, openForgotPasswordModal } = this.props;
        const { formatMessage } = this.props.intl;
        const { page } = this.state;
        return (
            <Form onSubmit={handleSubmit(submit)} className={'restauurant'}>
                {error && <span className={rs.errorMessage}>{error}</span>}
                {
                    page == 1 && <div>
                        <FormGroup className={s.formGroup}>
                            <Form.Label>{formatMessage(messages.email)} </Form.Label>
                            <Field name="email" label={formatMessage(messages.email)}
                                component={this.renderFormControl} placeholder={'Email Address'}
                                className={rs.formControlInputRestaurant}
                            />
                        </FormGroup>

                    </div>
                }
                {
                    page == 2 && <div>
                        <FormGroup className={s.formGroup}>
                            <Form.Label>{formatMessage(messages.password)} </Form.Label>
                            <Field name="password" type="password" label={formatMessage(messages.password)}
                                component={this.renderFormControl} placeholder={'Password'}
                                className={rs.formControlInputRestaurant}
                            />
                        </FormGroup>

                    </div>
                }
                <FormGroup className={s.formGroup}>
                    <Button className={cx(s.button, s.btnPrimaryBorder, s.btnLarge)} block type="submit" disabled={submitting}>
                        {page == 1 ? formatMessage(messages.next) : formatMessage(messages.signin)}
                    </Button>
                </FormGroup>
                <FormGroup className={cx(s.formGroup, s.formSection)}>
                    <Row>
                        {
                            page == 2 && <Col xs={12} sm={12} md={12} lg={12} className={cx(s.textAlignCenter)} >
                                <a href='javascript:void(0)' onClick={this.handleBack}><FormattedMessage {...messages.back} /></a>
                            </Col>
                        }
                        {
                            page == 1 && <Col xs={12} sm={12} md={12} lg={12} className={s.textAlignCenter}>
                                <a onClick={openForgotPasswordModal} className={s.modalCaptionLink}>
                                    <FormattedMessage {...messages.cantSignIn} />
                                </a>
                            </Col>
                        }
                    </Row>
                </FormGroup>
            </Form>
        )
    }
}

LoginForm = reduxForm({
    form: 'LoginForm',
    validate,

})(LoginForm);

const selector = formValueSelector('LoginForm');

const mapState = (state) => ({
    page: selector(state, 'page')
});

const mapDispatch = {
    change,
    openForgotPasswordModal
};

export default injectIntl(withStyles(s, rs)(connect(mapState, mapDispatch)(LoginForm)));