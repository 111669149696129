import gql from 'graphql-tag';
import { toastr } from 'react-redux-toastr';
import { closeForgotPasswordModal } from '../../modalActions';

export function sendForgotPasswordLink(email) {

    return async (dispatch, getState, { client }) => {

        dispatch(closeForgotPasswordModal());

        try {
            let mutation = gql`
                mutation sendForgotPasswordLink($email: String!) {
                    sendForgotPasswordLink (email: $email) {
                        status
                        errorMessage
                    }
                }
            `;

            const { data } = await client.mutate({
                mutation,
                variables: { email }
            });

            if (data && data.sendForgotPasswordLink) {
                if (data.sendForgotPasswordLink.status === 200) {
                    toastr.success("Enlace de restablecimiento enviado a su correo electrónico", "El correo electrónico con el enlace de restablecimiento está en camino a tu bandeja de entrada");
                    return true;
                } else {
                    toastr.error("Enviar enlace de restablecimiento falló", data.sendForgotPasswordLink.errorMessage);
                    return false;
                }
            } else {
                toastr.error("Enviar enlace de restablecimiento falló", "Algo ha ido mal, por favor, inténtelo de nuevo más tarde");
                return false;
            }
        } catch (error) {
            toastr.error("Enviar enlace de restablecimiento falló", "Algo ha ido mal, por favor, inténtelo de nuevo más tarde " + error);
            return false;
        }
    };
}